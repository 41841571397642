import React, { useState, useEffect } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Header from "../../components/Header";
import BarChart from "../../components/BarChart";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

const Bar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedDaterangeOption, setSelectedDaterangeOption] = useState(() => {
    return localStorage.getItem("selectedDataOption") || "Monthly";
  });

  useEffect(() => {
    localStorage.setItem("selectedDataOption", selectedDaterangeOption);
  }, [selectedDaterangeOption]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setSelectedDaterangeOption(option);
    handleClose();
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Charts"
          subtitle="View and analyze the data for all the Sections and figure out the reasons behind them."
        />
        <div>
          <Button
            id="download-button"
            aria-controls={open ? "download-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              minWidth: "200px",
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {selectedDaterangeOption} Data <ArrowDropDownIcon />
          </Button>
          <Menu
            id="download-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "download-button",
            }}
            PaperProps={{
              style: {
                width: anchorEl ? anchorEl.offsetWidth : 200,
              },
            }}
          >
            <MenuItem onClick={() => handleMenuItemClick("Weekly")}>
              Weekly Data
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("Monthly")}>
              Monthly Data
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("Yearly")}>
              Yearly Data
            </MenuItem>
          </Menu>
        </div>
      </Box>

      <Box height="75vh">
        <BarChart selectedDaterangeOption={selectedDaterangeOption} />
      </Box>
    </Box>
  );
};

export default Bar;
