import React, { useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Sheets from "./scenes/sheets";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import { NotFound, NotFoundButSignedInUser } from './scenes/notFound';
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Login from "./scenes/login";
import { AuthContext } from "./AuthContext";
import About from "./scenes/about";
import Services from "./scenes/services";
import ContactUs from "./scenes/contactUs";
import { useLocation } from "react-router-dom";
import ForgotPassword from "./scenes/login/forgotPassword";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const noSidebarPaths = ["/login", "/", "/services", "/contact"];
  const showSidebar = user && !noSidebarPaths.includes(location.pathname);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {noSidebarPaths.includes(location.pathname)}
          {showSidebar && <Sidebar isSidebar={isSidebar} />}
          <main className={user ? "content" : ""}>
            {showSidebar && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<About />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              {user && (
                <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/sheets" element={<Sheets />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/team/add" element={<Form />} />
                  <Route path="/charts" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/numbers" element={<Line />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/geography" element={<Geography />} />
                  <Route path="*" element={<NotFoundButSignedInUser />} />
                </>
              )}
              {!location.pathname.startsWith("/dashboard") && (
                <Route path="*" element={<NotFound />} />
              )}
            </Routes>
          </main>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
