export const downloadDenterSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const header =
    "Bus Number,Type of Complaint,Action Taken,In Time,Out Time,Completed,User Email";

    const csvRows = data.flatMap((denterSectionDoc) => {
      const workListDetails = denterSectionDoc.workListDetails || [];
      return workListDetails.map((work) => {
        const workFields = [
          denterSectionDoc.busPlateNumber,
          work.natureOfWork,
          work.actionTaken,
          work.inTime ? formatDateTime(work.inTime) : "",
          work.outTime ? formatDateTime(work.outTime) : "",
          work.isCompleted ? "Yes" : "No",
          denterSectionDoc.userEmail,
        ].join(", ");
  
        return workFields;
      });
    });

  const csvContent = [header, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadAccidentalSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const header =
    "Bus Number,Type of Complaint,Action Taken,Drivers Id,In Time,Out Time,Completed,Mechanic Name,User Email";

    const csvRows = data.flatMap((denterSectionDoc) => {
      const workListDetails = denterSectionDoc.workListDetails || [];
      return workListDetails.map((work) => {
        const workFields = [
          denterSectionDoc.busPlateNumber,
          work.natureOfWork,
          work.actionTaken,
          denterSectionDoc.driversId,
          work.inTime ? formatDateTime(work.inTime) : "",
          work.outTime ? formatDateTime(work.outTime) : "",
          work.isCompleted ? "Yes" : "No",
          denterSectionDoc.mechanicName,
          denterSectionDoc.userEmail,
        ].join(", ");
  
        return workFields;
      });
    });

  const csvContent = [header, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadGateSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder =
    "Bus Number ,Drivers Id,Out Km,In Km,Difference In Km,Out Time,In Time,Route Number,Accidental Case,Drivers Complaint,User Email";

  const csvRows = data.map((gateTrip) => {
    const workFields = [
      gateTrip.busPlateNumber,
      gateTrip.driversId,
      gateTrip.outKm,
      gateTrip.inKm,
      gateTrip.differenceInKm,
      gateTrip.outTime ? formatDateTime(gateTrip.outTime) : "",
      gateTrip.inTime ? formatDateTime(gateTrip.inTime) : "",
      gateTrip.routeId,
      gateTrip.accidentalCase,
      gateTrip.driversComplaint,
      gateTrip.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadAccidentalDataFromGate = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder =
    "Bus Number,In Km,Out Km,In Time,Out Time,Route Number,Accidental Case,Drivers Id,User Email";

  const csvRows = data.map((gateTrip) => {
    const workFields = [
      gateTrip.busPlateNumber,
      gateTrip.inKm,
      gateTrip.outKm,
      gateTrip.inTime ? formatDateTime(gateTrip.inTime) : "",
      gateTrip.outTime ? formatDateTime(gateTrip.outTime) : "",
      gateTrip.routeId,
      gateTrip.accidentalCase,
      gateTrip.driversId,
      gateTrip.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadDriverComplaintsDataFromGate = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder =
    "Bus Number,Route Number,Drivers Complaint,Drivers Id,User Email";

  const csvRows = data.map((gateTrip) => {
    const workFields = [
      gateTrip.busPlateNumber,
      gateTrip.routeId,
      gateTrip.driversComplaint,
      gateTrip.driversId,
      gateTrip.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadBusChargingSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder =
    "Bus Number,In Km,Out Km,Total Time Taken,In Charging Percentage,Out Charging Percentage,Total Charged Percentage,Charger Number,Number Of Trips While Charging,Completed,Remarks,User Email";

  const csvRows = data.map((busCharging) => {
    const workFields = [
      busCharging.busNumber,
      busCharging.inTime ? formatDateTime(busCharging.inTime) : "",
      busCharging.outTime ? formatDateTime(busCharging.outTime) : "",
      busCharging.totalTimeTaken,
      busCharging.inChargingPercentage,
      busCharging.outChargingPercentage,
      busCharging.outChargingPercentage - busCharging.inChargingPercentage,
      busCharging.chargerNumber,
      busCharging.numberOfTripCharger,
      busCharging.isCompleted ? "Yes" : "No",
      busCharging.remarks,
      busCharging.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadBreakdownSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder =
    "Bus Number,Drivers Id,Route Number,Time In,Complaint,In Km,Time Out,Action Taken,Completed,User Email";

  const csvRows = data.map((breakdown) => {
    const workFields = [
      breakdown.busPlateNumber,
      breakdown.driversId,
      breakdown.routeId,
      breakdown.timeIn ? formatDateTime(breakdown.timeIn) : "",
      breakdown.complaint,
      breakdown.inKm,
      breakdown.timeOut ? formatDateTime(breakdown.timeOut) : "",
      breakdown.actionTaken,
      breakdown.isCompleted ? "Yes" : "No",
      breakdown.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadBatterySectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder = "Bus Number,In Time,Battery Condition,Battery Maintenance,Greasing,Completed,Mechanic Name,User Email";

  const csvRows = data.map((entry) => {
    // Extracting and formatting relevant fields from each entry
    const workFields = [
      entry.busPlateNumber,
      entry.inTime ? formatDateTime(entry.inTime) : "",
      entry.batteryCondition,
      entry.batteryMaintenance ? "Yes" : "No",
      entry.greasing ? "Yes" : "No",
      entry.isCompleted ? "Yes" : "No",
      entry.mechanicName,
      entry.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadCngFillingSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder = "Bus Number,In Time,Out Time,Station Number,Total CNG Filled,Completed,Drivers Id,User Email,Remarks";

  const csvRows = data.map((entry) => {
    const workFields = [
      entry.busPlateNumber,
      entry.inTime ? formatDateTime(entry.inTime) : "",
      entry.outTime ? formatDateTime(entry.outTime) : "",
      entry.stationNumber,
      entry.totalCngFilled,
      entry.isCompleted ? "Yes" : "No",
      entry.driverName,
      entry.userEmail,
      entry.remarks,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadTyreSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder = "Bus Number,In Time,Out Time,Tyre Inflation,Tyre Punture,Tyre Km,Completed,Remarks,Mechanic Name,User Email";

  const csvRows = data.map((entry) => {
    const workFields = [
      entry.busPlateNumber,
      entry.inTime ? formatDateTime(entry.inTime) : "",
      entry.outTime ? formatDateTime(entry.outTime) : "",
      entry.tyreInflation,
      entry.tyrePunture === "yes" ? "Yes" : "No",
      entry.tyreKm,
      entry.isCompleted ? "Yes" : "No",
      entry.remarks,
      entry.mechanicName,
      entry.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};


export const downloadWashingSectionData = (
  data,
  startDate,
  endDate,
  collectionName
) => {
  const headerOrder = "Bus Number,In Time,Out Time,Washing,Deep Cleaning,Completed,Remarks,Mechanic Name,User Email";

  const csvRows = data.map((entry) => {
    const workFields = [
      entry.busPlateNumber,
      entry.inTime ? formatDateTime(entry.inTime) : "",
      entry.outTime ? formatDateTime(entry.outTime) : "",
      entry.washing ? "Yes" : "No",
      entry.deepCleaning ? "Yes" : "No",
      entry.isCompleted? "Yes" : "No",
      entry.remarks,
      entry.mechanicName,
      entry.userEmail,
    ].join(", ");

    return workFields;
  });

  const csvContent = [headerOrder, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};

export const downloadWorkshopSectionData = (data, startDate, endDate, collectionName) => {
  const header = "Bus Number ,Type of Complaint,Action Taken,Drivers Id,In Time,Out Time,Completed,Mechanic Name,User Email";

  const csvRows = data.flatMap((workshopSectionDoc) => {
    const workList = workshopSectionDoc.workListDetails || [];
    return workList.map((work) => {
      ////console.log("Inspecting work object:", work.inTime);
      const workFields = [
        workshopSectionDoc.busPlateNumber,
        work.natureOfWork,
        workshopSectionDoc.driversId,
        work.inTime ? formatDateTime(work.inTime) : "",
        work.outTime ? formatDateTime(work.outTime) : "",
        work.isCompleted ? "Yes" : "No",
        work.actionTaken,
        workshopSectionDoc.mechanicName,
        workshopSectionDoc.userEmail,
      ].join(", ");

      return workFields;
    });
  });

const csvContent = [header, ...csvRows].join("\n");

  downloadData(csvContent, collectionName, startDate, endDate);
};


const downloadData = (csvContent, collectionName, startDate, endDate) => {
  if (csvContent) {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute(
      "download",
      `${collectionName} ${formattedStartDate} to ${formattedEndDate}.csv`
    );
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    alert("No data to download.");
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
};

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
};
