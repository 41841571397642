import {
  fetchGateSectionData,
  fetchDenterSectionData,
  fetchAccidentalDataFromGate,
  fetchDriverComplaintsDataFromGate,
  fetchBusChargingSectionData,
  fetchBreakdownSectionData,
  fetchAccidentalSectionData,
  fetchBatterySectionData,
  fetchCngFillingSectionData,
  fetchTyreSectionData,
  fetchWashingSectionData,
  fetchWorkshopSectionData,
} from "../../data/fetchDataOperations";
import {
  downloadGateSectionData,
  downloadDenterSectionData,
  downloadAccidentalDataFromGate,
  downloadDriverComplaintsDataFromGate,
  downloadBusChargingSectionData,
  downloadBreakdownSectionData,
  downloadAccidentalSectionData,
  downloadBatterySectionData,
  downloadCngFillingSectionData,
  downloadTyreSectionData,
  downloadWashingSectionData,
  downloadWorkshopSectionData,
} from "../../data/downloadDataOperations";

// Factory to handle the download process based on the collection name
const DownloadHandlerFactory = {
  handleDownload: async (collectionName, startDate, endDate) => {
    switch (collectionName) {
      case "Gate Section":
        const gateData = await fetchGateSectionData(startDate, endDate);
        //console.log("gateData", gateData);
        downloadGateSectionData(gateData, startDate, endDate, "Gate Section");
        break;
      case "Denter Section":
        const denterData = await fetchDenterSectionData(startDate, endDate);
        //console.log("denterData", denterData);
        downloadDenterSectionData(
          denterData,
          startDate,
          endDate,
          "Denter Section"
        );
        break;
      case "Accidental Cases From Gate":
        const accidentalDataFromGate = await fetchAccidentalDataFromGate(
          startDate,
          endDate
        );
        //console.log("accidentalDataFromGate", accidentalDataFromGate);
        downloadAccidentalDataFromGate(
          accidentalDataFromGate,
          startDate,
          endDate,
          "Accidental Cases From Gate"
        );
        break;
      case "Driver Complaints From Gate":
        const driverComplaintsDataFromGate =
          await fetchDriverComplaintsDataFromGate(startDate, endDate);
        //console.log(
        //   "DriverComplaintsDataFromGate",
        //   driverComplaintsDataFromGate
        // );
        downloadDriverComplaintsDataFromGate(
          driverComplaintsDataFromGate,
          startDate,
          endDate,
          "Driver Complaints From Gate"
        );
        break;
      case "Bus Charging Section":
        const busChargingSectionData = await fetchBusChargingSectionData(
          startDate,
          endDate
        );
        //console.log("BusChargingSectionData", busChargingSectionData);
        downloadBusChargingSectionData(
          busChargingSectionData,
          startDate,
          endDate,
          "Bus Charging Section"
        );
        break;
      case "Breakdown Section":
        const breakdownSectionData = await fetchBreakdownSectionData(
          startDate,
          endDate
        );
        //console.log("BreakdownSectionData", breakdownSectionData);
        downloadBreakdownSectionData(
          breakdownSectionData,
          startDate,
          endDate,
          "Breakdown Section"
        );
        break;
      case "Accidental Repair Section":
        const accidentalRepairSectionData = await fetchAccidentalSectionData(
          startDate,
          endDate
        );
        //console.log("accidentalRepairSectionData", accidentalRepairSectionData);
        downloadAccidentalSectionData(
          accidentalRepairSectionData,
          startDate,
          endDate,
          "Accidental Repair Section"
        );
        break;
      case "Battery Section":
        const batterySectionData = await fetchBatterySectionData(
          startDate,
          endDate
        );
        //console.log("batterySectionData", batterySectionData);
        downloadBatterySectionData(
          batterySectionData,
          startDate,
          endDate,
          "Battery Section"
        );
        break;
      case "Cng Filling Section":
        const cngFillingSectionData = await fetchCngFillingSectionData(
          startDate,
          endDate
        );
        //console.log("cngFillingSectionData", cngFillingSectionData);
        downloadCngFillingSectionData(
          cngFillingSectionData,
          startDate,
          endDate,
          "Cng Filling Section"
        );
        break;
      case "Tyre Section":
        const tyreSectionData = await fetchTyreSectionData(
          startDate,
          endDate
        );
        //console.log("tyreSectionData", tyreSectionData);
        downloadTyreSectionData(
          tyreSectionData,
          startDate,
          endDate,
          "Tyre Section"
        );
        break;
      case "Washing Section":
        const washingSectionData = await fetchWashingSectionData(
          startDate,
          endDate
        );
        //console.log("washingSectionData", washingSectionData);
        downloadWashingSectionData(
          washingSectionData,
          startDate,
          endDate,
          "Washing Section"
        );
        break;
      case "Workshop Section":
        const workshopSectionData = await fetchWorkshopSectionData(
          startDate,
          endDate
        );
        //console.log("workshopSectionData", workshopSectionData);
        downloadWorkshopSectionData(
          workshopSectionData,
          startDate,
          endDate,
          "Workshop Section"
        );
        break;
      // Add more cases here...
      default:
        throw new Error("Unknown collection name");
    }
  },
};

export default DownloadHandlerFactory;
