import { useState, useEffect } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import * as Realm from "realm-web";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
const user = app.currentUser; // Ensure the user is authenticated

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentViewRange, setCurrentViewRange] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    // Inject custom styles
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
    .fc-popover { color: black; }
    .fc-timegrid-slot:hover, .fc-daygrid-day:hover, .fc-list-event:hover { background-color: grey; }
    .fc-list-day-text, .fc-list-day-side-text { color: black; }
    .fc-list-event-title { color: white !important; }
    .fc-daygrid-day, .fc-daygrid-day-top, .fc-daygrid-day-number, .fc-axis, .fc-scrollgrid-section-header, colgroup, .fc-col-header-cell, .fc-day, .fc-day-sun, .fc-col-header-cell-cushion, .fc-daygrid-more-link, .fc-more-link, .fc-event-time, .fc-event-title { color: white !important; }
    .fc-daygrid-day-events { color: white !important; }
    .fc-daygrid-divider, .fc-timegrid-slot-lane { border-color: grey !important; }
    .fc-popover .fc-event-time, .fc-popover .fc-event-title { color: black !important; font-weight: bold; }
    .fc-popover-header { color: black !important; font-weight: bold; }
    .fc-list-event:hover .fc-list-event-title, .fc-list-event:hover .fc-list-event-time { color: black !important; }
    `;

    document.head.appendChild(style);

    // Cleanup to remove the style tag when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!user) {
        console.error("User not authenticated");
        return;
      }
      try {
        const eventsCollection = user
          .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
          .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
          .collection(process.env.REACT_APP_MONGO_DB_CALENDAR_TABLE);
        const events = await eventsCollection.find({
          userId: user.profile.email,
        });
        setCurrentEvents(
          events.map((event) => ({
            id: event._id.toString(),
            title: event.title,
            start: event.start,
            end: event.end,
            allDay: event.allDay,
          }))
        );
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [user]);

  const handleDateClick = async (selected) => {
    const title = prompt("Please enter a new title for your event");
    if (title) {
      try {
        const eventsCollection = user
          .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
          .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
          .collection(process.env.REACT_APP_MONGO_DB_CALENDAR_TABLE);
        const addedEvent = await eventsCollection.insertOne({
          title,
          start: selected.startStr,
          end: selected.endStr,
          allDay: selected.allDay,
          userId: user.profile.email,
        });

        setCurrentEvents([
          ...currentEvents,
          {
            id: addedEvent.insertedId.toString(),
            title,
            start: selected.startStr,
            end: selected.endStr,
            allDay: selected.allDay,
          },
        ]);
      } catch (error) {
        console.error("Error adding event:", error);
      }
    }
  };

  const handleEventClick = async (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'?`
      )
    ) {
      const eventId = clickInfo.event.id;

      // Attempt to delete the event from the MongoDB database
      try {
        const eventsCollection = user
          .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
          .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
          .collection(process.env.REACT_APP_MONGO_DB_CALENDAR_TABLE);
        await eventsCollection.deleteOne({
          _id: new Realm.BSON.ObjectId(eventId),
        });

        // If successful, remove the event from the FullCalendar UI
        clickInfo.event.remove();

        // Update the `currentEvents` state to reflect the deletion
        setCurrentEvents(currentEvents.filter((event) => event.id !== eventId));
      } catch (error) {
        console.error("Error deleting event from database:", error);
        alert("Failed to delete the event. Please try again.");
      }
    }
  };

  const handleDatesSet = (dateInfo) => {
    setCurrentViewRange({
      start: dateInfo.startStr,
      end: dateInfo.endStr,
      currentMonth: dateInfo.start.getMonth(), // Get the current viewed month
      currentYear: dateInfo.start.getFullYear(), // Get the current viewed year
    });
  };

  const eventsForCurrentMonth = currentEvents
    .filter((event) => {
      const start = new Date(event.start).getTime();
      const end = event.end ? new Date(event.end).getTime() : start;
      const viewStart = new Date(currentViewRange.start).getTime();
      const viewEnd = new Date(currentViewRange.end).getTime();
      return start >= viewStart && start <= viewEnd;
    })
    .sort((a, b) => new Date(a.start) - new Date(b.start));

  return (
    <Box m="20px">
      {theme.palette.mode === "dark" ? (
        <>
          <Header
            title="Calendar"
            subtitle="Add tasks/events to the calendar and view them in monthly, weekly, or daily view"
          />

          <Box display="flex" justifyContent="space-between">
            {/* CALENDAR SIDEBAR */}
            <Box
              flex="1 1 20%"
              backgroundColor={colors.primary[400]}
              p="15px"
              borderRadius="4px"
            >
              <Typography variant="h5">Events for Current View</Typography>
              <List>
                {eventsForCurrentMonth.map((event) => (
                  <ListItem
                    key={event.id}
                    sx={{
                      backgroundColor: colors.greenAccent[500],
                      margin: "10px 0",
                      borderRadius: "2px",
                    }}
                  >
                    <ListItemText
                      primary={event.title}
                      secondary={formatDate(event.start, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* CALENDAR */}
            <Box flex="1 1 100%" ml="15px" paddingBottom="10px">
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  //listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateClick}
                eventClick={handleEventClick}
                datesSet={handleDatesSet}
                events={currentEvents}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Header
            title="Calendar is only available in dark mode"
            subtitle="(Coming Soon in Light mode)"
          />
      )}
    </Box>
  );
};

export default Calendar;
