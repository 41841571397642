import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import * as Realm from "realm-web";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ isCollapsed, setIsCollapsed, setSelectedTab, selected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentUserEmail, setCurrentUserEmail] = useState("-");

  useEffect(() => {
    const user = app.currentUser;
    const userEmail = user ? user.profile.email : "Admin Email";

    if (userEmail.includes("@")) {
      const emailParts = userEmail.split("@");
      setCurrentUserEmail(emailParts[0]);
    } else {
      setCurrentUserEmail(userEmail);
    }
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        zIndex: 1000,
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ADMIN PANEL
                </Typography>
                <IconButton
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  sx={{
                    "&:hover": {
                      color: "#868dfb", // Hover color for the icon
                    },
                  }}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/${
                    theme.palette.mode === "dark" ? "user3.png" : "user1.png"
                  }`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  paddingTop="15px"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {currentUserEmail}
                </Typography>
                <Typography
                  variant="h4"
                  paddingBottom="20px"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  myDepotApp
                </Typography>
              </Box>
            </Box>
          )}
          <Divider
            style={{
              backgroundColor: colors.grey[300],
              margin: "10px 20px",
              marginBottom: "15px",
            }}
          />
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Team
            </Typography>
            <Item
              title="Manage Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />
            <Item
              title="Add Users"
              to="/team/add"
              icon={<PersonAddAltIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="Download Sheets"
              to="/sheets"
              icon={<BrowserUpdatedIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {isCollapsed ? "Charts" : "Charts & Numbers"}
            </Typography>
            <Item
              title="Charts"
              to="/charts"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />
            <Item
              title="Numbers"
              to="/numbers"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelectedTab}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

const AppLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const isCollapsedStored = localStorage.getItem("isCollapsedSidebar");
    return isCollapsedStored === "true"; // Convert string to boolean
  });
  const [selected, setSelected] = useState(
    localStorage.getItem("selectedTab") || "Dashboard"
  );

  const setSelectedTab = (title) => {
    setSelected(title);
    localStorage.setItem("selectedTab", title);
  };

  const location = useLocation();

  const handleSetIsCollapsed = (collapsed) => {
    setIsCollapsed(collapsed);
      localStorage.setItem("isCollapsedSidebar", collapsed.toString());
      if (isCollapsed !== collapsed && location.pathname === '/calendar') {
      setTimeout(() => {
        window.location.reload(); // Refresh the page
      }, 250);
    }
  };

  return (
    <div>
      <Sidebar
        isCollapsed={isCollapsed}
        setIsCollapsed={handleSetIsCollapsed}
        setSelectedTab={setSelectedTab}
        selected={selected}
      />
      <div
        style={{
          marginLeft: isCollapsed ? "80px" : "265px", // Adjust these widths based on your sidebar's actual widths
          transition: "margin-left 0.3s ease",
        }}
      >
        {/* Main content goes here. This div should wrap around all of your app's content except the sidebar. */}
      </div>
    </div>
  );
};

export default AppLayout;
