import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  CssBaseline,
  Card,
  CardContent,
  Container,
  Divider,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import { Download, BarChart2, Bell, Smartphone, WifiOff, Clock, Database } from "lucide-react";

// Create an updated theme with smoother color contrasts and responsive typography
let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4CAF50',
    },
    secondary: {
      main: '#81C784',
    },
    background: {
      default: 'hsl(200, 23%, 8%)',
      paper: 'hsl(200, 23%, 12%)',
    },
    text: {
      primary: '#d0edd6',
      secondary: '#81C784',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2.5rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    body1: {
      fontSize: '1.125rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '1rem',
          padding: '10px 24px',
        },
        containedPrimary: {
          backgroundColor: '#4CAF50',
          '&:hover': {
            backgroundColor: '#45a049',
            transform: 'scale(1.05)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          padding: '20px',
          boxShadow: '0 6px 20px 0 rgba(0,0,0,0.15)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 12px 30px 0 rgba(0,0,0,0.2)',
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const LandingPage = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    document.body.style.backgroundColor = "hsl(200, 23%, 8%)";
    document.body.style.color = "#d0edd6";
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.color = "";
    };
  }, []);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      setActiveSection(hash);
    } else {
      setActiveSection("home");
    }
  }, [location]);

  const FeatureCard = ({ icon: Icon, title, description }) => (
    <Card sx={{ height: '100%', backgroundColor: 'background.paper' }}>
      <CardContent>
        <Icon size={48} color={theme.palette.primary.main} />
        <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 2 }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );

  const renderServices = () => (
    <Container maxWidth="lg" sx={{ mt: 10, mb: 10 }}>
      <Typography variant="h2" gutterBottom align="center" sx={{ mb: 6 }}>
        Our Solutions & Services
      </Typography>
      <Typography variant="h5" gutterBottom align="center" sx={{ mb: 8 }}>
        Unlocking Operational Excellence for your depot with Customized myDepot App Services
      </Typography>
      
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <FeatureCard 
            icon={Download}
            title="Easy Data Access"
            description="Download important data like daily travel, monthly meetings, gate details, and dentor section info with just one click. Choose your dates and get your Excel sheet instantly."
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FeatureCard 
            icon={BarChart2}
            title="Analytical Charts"
            description="Get insights on breakdowns, driver performance, task completion ratios, and average completion times for each section, including denter tasks."
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FeatureCard 
            icon={Bell}
            title="Custom Alerts"
            description="Stay informed with alerts for PUC dates, ten-day forecasts, MVI schedules, and more. Never miss crucial bus metrics again."
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 8 }} />

      <Typography variant="h3" gutterBottom align="center" sx={{ mb: 6 }}>
        Our Eco-system
      </Typography>
      
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <FeatureCard 
            icon={Smartphone}
            title="User-Friendly Mobile App"
            description="Download the myDepot app from the Play Store. Log in, input data, and submit. It's that simple for all incharge types."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FeatureCard 
            icon={Database}
            title="Tailored UI/UX"
            description="Each incharge type has a unique interface tailored to their specific needs and functionalities."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FeatureCard 
            icon={Clock}
            title="Instant Data Access"
            description="MIS operators and admins can download data immediately after submission. No delays, no waiting for shifts to change."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FeatureCard 
            icon={WifiOff}
            title="Offline Functionality"
            description="Work offline and sync automatically when reconnected. Input and submit data even without an internet connection."
          />
        </Grid>
      </Grid>
    </Container>
  );

  const renderContent = () => {
    switch (activeSection) {
      case "about":
        return (
          <Box sx={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0, zIndex: -1 }}>
            <img src="../../assets/comingSoon.jpg" alt="Coming Soon" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Box>
        );
      case "services":
        return renderServices();
      default:
        return (
          <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
            <Typography variant="h1" component="h1" gutterBottom align="center">
              Welcome to myDepot App
            </Typography>
            <Typography variant="h4" gutterBottom align="center" sx={{ mb: 6 }}>
              Your all-in-one solution for Modernizing Depot Operations
            </Typography>
            <Paper elevation={3} sx={{ p: 4, bgcolor: 'background.paper' }}>
              <Typography variant="h4" gutterBottom>
                About Uss
              </Typography>
              <Typography variant="body1" paragraph>
                We're dedicated to designing and developing systems that allow depots to operate efficiently and effortlessly, without the need for pen or paper, by providing digital solutions for data management.
              </Typography>
              <Typography variant="body1" paragraph>
                Your depot, our eco-system. We create an eco-system that connects the mobile phones of different incharge types to the computers of MIS operators/admin.
              </Typography>
            </Paper>
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button variant="contained" color="primary" size="large">
                Get Started
              </Button>
            </Box>
          </Container>
        );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        position: "fixed", 
        top: 0, 
        width: "100%", 
        display: "flex",
        justifyContent: "center", /* Centering Navbar horizontally */
        zIndex: 1000 
      }}>
        <Navbar />
      </Box>
      <Box component="main" sx={{ 
        mt: 22, 
        minHeight: '100vh', 
        display: "flex", 
        flexDirection: "column",
        justifyContent: "center", /* Centers the content vertically */
        alignItems: "center" /* Centers the content horizontally */
      }}>
        {renderContent()}
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
