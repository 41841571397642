import * as Realm from "realm-web";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
const user = app.currentUser;

export const adjustedEndDate = (endDate) => {
  return new Date(new Date(endDate).setHours(23, 59, 59, 999));
};

const getCurrentUserDepotId = async () => {
  const userCollection = user
    .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
  const currentUserDetails = await userCollection.findOne({
    email: user.profile.email,
  });
  return currentUserDetails.depotId;
};

const getCollection = (collectionName) => {
  return user
    .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection(collectionName);
};

export const fetchDenterSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_DENTER_SECTION_TABLE
  );

  const pipeline = [
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        taskCreationTime: {
          $gte: new Date(startDate),
          $lte: adjustedEndDate(endDate),
        },
      },
    },
    {
      $lookup: {
        from: process.env.REACT_APP_MONGO_DB_NATURE_OF_WORK_TABLE, // The name of the collection to join.
        localField: "workList", // The field in the DenterSection collection.
        foreignField: "_id", // The field in the NatureOfWork collection.
        as: "workListDetails", // The name of the new array field to add to the input documents.
      },
    },
  ];

  try {
    return await collection.aggregate(pipeline);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchGateSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_GATE_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchAccidentalDataFromGate = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_GATE_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
    accidentalCase: { $ne: null, $ne: "" },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchDriverComplaintsDataFromGate = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_GATE_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
    driversComplaint: { $ne: null, $ne: "" },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchBreakdownSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_BREAKDOWN_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    timeIn: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchBusChargingSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_BUS_CHARGING_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchAccidentalSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_ACCIDENTAL_REPAIR_SECTION_TABLE
  );

  const pipeline = [
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        taskCreationTime: {
          $gte: new Date(startDate),
          $lte: adjustedEndDate(endDate),
        },
      },
    },
    {
      $lookup: {
        from: process.env.REACT_APP_MONGO_DB_NATURE_OF_WORK_TABLE, // The name of the collection to join.
        localField: "workList", // The field in the DenterSection collection.
        foreignField: "_id", // The field in the NatureOfWork collection.
        as: "workListDetails", // The name of the new array field to add to the input documents.
      },
    },
  ];
  try {
    return await collection.aggregate(pipeline);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchBatterySectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_BATTERY_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchCngFillingSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_CNG_FILLING_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchTyreSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_TYRE_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchWashingSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_WASHING_SECTION_TABLE
  );

  const query = {
    $or: [
      { depotId: depotId.toString() },
      { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
    ],
    inTime: { $gte: new Date(startDate), $lte: adjustedEndDate(endDate) },
  };

  try {
    return await collection.find(query);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchWorkshopSectionData = async (startDate, endDate) => {
  const depotId = await getCurrentUserDepotId();
  const collection = getCollection(
    process.env.REACT_APP_MONGO_DB_WORKSHOP_SECTION_TABLE
  );

  const pipeline = [
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        taskCreationTime: {
          $gte: new Date(startDate),
          $lte: adjustedEndDate(endDate),
        },
      },
    },
    {
      $lookup: {
        from: process.env.REACT_APP_MONGO_DB_NATURE_OF_WORK_TABLE, // The name of the collection to join.
        localField: "workList", // The field in the DenterSection collection.
        foreignField: "_id", // The field in the NatureOfWork collection.
        as: "workListDetails", // The name of the new array field to add to the input documents.
      },
    },
  ];
  try {
   // //console.log("12312", collection.aggregate(pipeline));
    return await collection.aggregate(pipeline);
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};
