import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Grid,
  CssBaseline,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "../../components/navbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Form } from "react-router-dom";
import "./index.css";
const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& input": {
            color: "#d0edd6",
            caretColor: "#d0edd6",
            backgroundColor: "transparent", // Ensure background stays transparent
          },
          "& textarea": {
            color: "#d0edd6",
            caretColor: "#d0edd6",
            backgroundColor: "transparent", // Ensure background stays transparent for TextareaAutosize
          },
          "& .MuiSelect-select": {
            color: "#d0edd6",
            backgroundColor: "transparent", // Ensure background stays transparent for select dropdown
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d0edd6", // Maintain the custom border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d0edd6", // Maintain custom border color on focus
          },
          "&.Mui-autofilled": {
            backgroundColor: "transparent", // Maintain transparent background on autofill
          },
        },
        notchedOutline: {
          borderColor: "#d0edd6", // Initial border color
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#d0edd6",
          "&.Mui-focused": {
            color: "#d0edd6",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: "#d0edd6",
        },
      },
    },
  },
});

// Define a style object for text fields
const textFieldStyles = {
  marginBottom: "20px", // Add padding between the fields
  "& .MuiInputBase-root": {
    color: "#d0edd6", // Text color
    "& fieldset": {
      borderColor: "#d0edd6", // Border color matches text color
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main, // Keep border color blue when focused
    },
  },
};

const ContactUs = () => {
  const [formData, setFormData] = React.useState({
    email: "",
    phoneNumber: "",
    heardFrom: "",
    depotName: "",
    state: "",
    message: "",
  });

  useEffect(() => {
    document.body.style.backgroundColor = "hsl(200, 23%, 8%)";
    document.body.style.color = "#d0edd6";
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.color = "";
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Capitalize the first letter of the value
    const capitalizedValue =
      name === "depotName" || name === "state"
        ? value.charAt(0).toUpperCase() + value.slice(1)
        : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: capitalizedValue,
    }));
  };

  const selectStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d0edd6", // Default border color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main, // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main, // Border color when focused
      },
    },
    marginBottom: "20px", // Consistent spacing with TextField components
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation Checks
    if (!formData.email) {
      toast.error("Email is required.");
      return;
    }
    if (
      !/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(
        formData.email
      )
    ) {
      toast.error("Invalid email format.");
      return;
    }
    if (
      !formData.phoneNumber ||
      formData.phoneNumber.length < 10 ||
      !/^\d+$/.test(formData.phoneNumber)
    ) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    if (!formData.depotName) {
      toast.error("Depot name is required.");
      return;
    }
    if (!formData.state) {
      toast.error("State is required.");
      return;
    }
    if (!formData.heardFrom) {
      toast.error("Please select how you heard about us.");
      return;
    }

    try {
      const response = await fetch("https://formspree.io/f/mdoqwzyw", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Thank you for your submission!");
        // Reset form state here
        setFormData({
          email: "",
          phoneNumber: "",
          heardFrom: "",
          depotName: "",
          state: "",
          message: "",
        });
      } else {
        toast.error("Submission failed, please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred, please try again.");
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {" "}
        {/* Wrap your component with ThemeProvider */}
        <CssBaseline /> {/* Ensure baseline styles are applied */}
        <Box sx={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
          <Navbar />
        </Box>
        <Grid container spacing={2} sx={{ mt: 22, padding: 3 }}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{ fontWeight: "bold", fontSize: "2.5rem", mb: 4 }}
            >
              Let’s work together & create something great
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "1.25rem", mb: 35 }}
            >
              Take the first step by filling out our enquiry form below, and
              someone from our team will reach back to you within two business
              days.
            </Typography>
            {/* <Box sx={{ height: 270}} /> */}
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "1rem", mt: "auto" }}
            >
              Reach out to us on mydepotapphelp@gmail.com in case of any
              queries.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {" "}
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                maxWidth: "500px", // Increased maxWidth for the form
                width: "100%", // Ensure form takes up full width of its container
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <form>
                <TextField
                  required
                  label="Contact Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  sx={textFieldStyles} // Apply custom styles
                />

                <TextField
                  required
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  fullWidth
                  sx={textFieldStyles} // Apply custom styles
                />

                <TextField
                  required
                  label="Your Depot Name and Location"
                  name="depotName"
                  value={formData.depotName}
                  onChange={handleChange}
                  fullWidth
                  sx={textFieldStyles} // Apply custom styles
                />

                <TextField
                  required
                  label="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  fullWidth
                  sx={textFieldStyles} // Apply custom styles
                />

                <FormControl fullWidth sx={selectStyles}>
                  <InputLabel>How did you hear about us?</InputLabel>
                  <Select
                    required
                    name="heardFrom"
                    value={formData.heardFrom}
                    label="How did you hear about us?"
                    onChange={handleChange}
                  >
                    <MenuItem value="Internet">Internet</MenuItem>
                    <MenuItem value="Friend">Friend</MenuItem>
                    <MenuItem value="Fellow Depots">Fellow Depots</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="subtitle1" gutterBottom>
                  Any message for us? (optional)
                </Typography>
                <TextareaAutosize
                  minRows={3}
                  style={{ width: "100%" }} // Ensure the textarea takes the full width
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </form>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "auto" }}
              >
                Send Enquiry
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default ContactUs;
