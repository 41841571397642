import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Realm from "realm-web";
import { BSON } from "realm-web";
import { toast } from "react-toastify";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const user = app.currentUser;

  const handleFormSubmit = async (values, { resetForm }) => {
    //console.log("Email:", values.email); // Log email
    //console.log("Password:", values.password); // Log password

    if (!values.email.endsWith("@mydepotapp.com")) {
      toast.info("Please create email that ends with @mydepotapp.com");
      return; // Exit the function early if the check fails
    }

    if (!user) {
      console.error("No user is currently logged in.");
      return;
    }

    //console.log(user.profile.email);
    try {
      // Step 1: Create a new user in Realm using email/password authentication
      await app.emailPasswordAuth.registerUser({
        email: values.email,
        password: values.password,
      });

      // Step 2: Retrieve current user's depotId and busRTOcodes
      const userCollection = user
        .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
        .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
        .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
      const currentUserDetails = await userCollection.findOne({
        email: user.profile.email, // Use userEmail as the identifier
      });
      if (!currentUserDetails) {
        throw new Error("Current user's details not found.");
      }
      //console.log("details", currentUserDetails);

      // Step 3: Add the new user to the 'User' table
      const newUser = {
        _id: new BSON.ObjectId(),
        busRTOcodes: currentUserDetails.busRTOcodes,
        depotId: new BSON.ObjectId(currentUserDetails.depotId),
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        inchargeType: values.inchargeType,
      };

      const result = await userCollection.insertOne(newUser);

      //console.log("newUser", result);
      //console.log("User created successfully");
      toast.success("New user created in myDepotApp");
      resetForm();
    } catch (error) {
      console.error("Error creating user:", error);
      // Check if the error status code is 409, indicating a conflict (e.g., username or email already exists)
      if (
        error instanceof Realm.MongoDBRealmError &&
        error.statusCode === 409
      ) {
        toast.error("This Email is already taken. Please try another one.");
      } else {
        toast.error("Error creating new user in myDepotApp");
      }

      if (error instanceof Realm.MongoDBRealmError) {
        console.error(
          "Error details:",
          error.statusCode,
          error.error,
          error.link
        );
      }
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New Incharge" />

      <Formik
        onSubmit={(values, formikBag) => handleFormSubmit(values, formikBag)}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                <InputLabel id="incharge-type-label">Incharge Type</InputLabel>
                <Select
                  labelId="incharge-type-label"
                  value={values.inchargeType}
                  label="Incharge Type"
                  onChange={(e) =>
                    setFieldValue("inchargeType", e.target.value)
                  }
                  error={touched.inchargeType && Boolean(errors.inchargeType)}
                  onBlur={handleBlur}
                  name="inchargeType"
                  variant="outlined"
                  sx={{
                    "&.Mui-focused": {
                      border: "1px solid grey", // Border when focused
                    },
                  }}
                >
                <MenuItem value="" disabled>
                  Select Incharge Type
                </MenuItem>
                  <MenuItem value="BusChargingSection">Bus Charging Section</MenuItem>
                  <MenuItem value="Gate">Gate Section</MenuItem>
                  <MenuItem value="DenterSection">Denter Section</MenuItem>
                  <MenuItem value="BreakdownSection">Breakdown Section</MenuItem>
                  <MenuItem value="AccidentalRepairSection">Accidental Repair Section</MenuItem>
                  <MenuItem value="CngFillingSection">Cng Filling Section</MenuItem>
                  <MenuItem value="WorkshopSection">Workshop Section</MenuItem>
                  <MenuItem value="WashingSection">Washing Section</MenuItem>
                  <MenuItem value="BatterySection">Battery Section</MenuItem>
                  <MenuItem value="TyreSection">Tyre Section</MenuItem>
                  {/* Add other options as needed */}
                </Select>
                {touched.inchargeType && errors.inchargeType && (
                  <Box color="error.main" mt={1}>
                    {errors.inchargeType}
                  </Box>
                )}
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type={values.showPassword ? "text" : "password"}
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }} // Span 4 columns to make it wider
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setFieldValue("showPassword", !values.showPassword)
                        }
                        edge="end"
                        sx={{ marginRight: "8px" }}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                variant="filled"
                type={values.showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                name="confirmPassword"
                error={!!touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ gridColumn: "span 4" }} // Span 4 columns to make it wider
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setFieldValue(
                            "showConfirmPassword",
                            !values.showConfirmPassword
                          )
                        }
                        edge="end"
                        sx={{ marginRight: "8px" }}
                      >
                        {values.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{5,}$/;
const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().required("required"), //.email("invalid email")
  password: yup
    .string()
    .matches(
      passwordRegex,
      "Password must contain at least one letter, one number and be at least 5 characters long"
    )
    .required("required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  inchargeType: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  inchargeType: "",
  showPassword: false,
  showConfirmPassword: false,
};

export default Form;
