import React, { useState } from "react";
import { Box, Button, useTheme, TextField } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DownloadHandlerFactory from "./DownloadHandlerFactory";
import { toast } from "react-toastify";

const Sheets = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dates, setDates] = useState({});
  const [density, setDensity] = useState("comfortable");

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/* Add other toolbar buttons if needed */}
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dates[params.id]?.startDate || null}
            onChange={(newValue) => {
              setDates({
                ...dates,
                [params.id]: { ...dates[params.id], startDate: newValue },
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dates[params.id]?.endDate || null}
            onChange={(newValue) => {
              setDates({
                ...dates,
                [params.id]: { ...dates[params.id], endDate: newValue },
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: "download",
      headerName: "Download",
      renderCell: (params) => (
        <Button
          onClick={() => handleDownload(params.row.name, params.id)}
          sx={{
            //width: "60%",
            backgroundColor: colors.blueAccent[100],
            color: theme.palette.mode === "light" ? "white" : "black", // Dynamically setting text color
            "&:hover": {
              backgroundColor: colors.grey[300],
              color: "white", // Set hover text color to white
            },
          }}
        >
          Download
        </Button>
      ),
      flex: 1,
    },
  ];

  const rows = [
    { id: 1, name: "Gate Section" },
    { id: 2, name: "Denter Section" },
    { id: 3, name: "Bus Charging Section" },
    { id: 4, name: "Accidental Cases From Gate" },
    { id: 5, name: "Driver Complaints From Gate" },
    { id: 6, name: "Breakdown Section" },
    { id: 7, name: "Accidental Repair Section" },
    { id: 8, name: "Battery Section" },
    { id: 9, name: "Cng Filling Section" },
    { id: 10, name: "Tyre Section" },
    { id: 11, name: "Washing Section" },
    { id: 12, name: "Workshop Section" },
  ];

  const handleDownload = async (collectionName, rowId) => {
    const rowDates = dates[rowId];
    if (rowDates && rowDates.startDate && rowDates.endDate) {
      try {
        await DownloadHandlerFactory.handleDownload(
          collectionName,
          rowDates.startDate,
          rowDates.endDate
        );
        toast.success("Sheet Downloaded");
      } catch (error) {
        console.error(error.message);
        toast.error("An error occurred or No data found.");
      }
    } else {
      toast.error("Please select both Start and End dates.");
    }
  };

  return (
    <Box m="20px">
      <Header
        title="DOWNLOAD SHEETS"
        subtitle="Download excel sheets for each section with customised dates"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            //color: colors.greenAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          density={density}
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Sheets;
