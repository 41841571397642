import { Box, IconButton, useTheme } from "@mui/material";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      logout();
      localStorage.clear();
      navigate("/login");
    }
  };

  const handleBusIconClick = () => {
    toast.info("Support email - mydepotapphelp@gmail.com");
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.blueAccent[700]}
        borderRadius="3px"
        alignItems="center" // Center the content vertically
        sx={{ width: "100%", padding: " 3px" }}
      >
        <Typography
          variant="body1"
          sx={{ ml: 2, fontWeight: "bold", color: "white", fontSize: "17px" }}
        >
          myDepotApp
        </Typography>
        <IconButton
          sx={{ marginLeft: "auto", p: 1, marginRight: 1 }}
          onClick={handleBusIconClick}
        >
          <SettingsOutlinedIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex" sx={{ ml: 2 }}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
