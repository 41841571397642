import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`navbar-container ${isScrolled ? "scrolled" : ""}`}>
      <div className="nav-bar">
        <div className="logo-container">
          <Link to="/">
            <img
              src="/assets/mydepotAppLogo.png"
              alt="MyDepot App Logo"
              width="80"
              height="80"
              className="logo-image"
            />
          </Link>
        </div>

        <div className="welcome-message">
          <h1>MyDepotApp</h1>
        </div>

        <div className="nav-menu">
          <a href="https://about.mydepotapp.com" className="nav-button bounce">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
