import React, { createContext, useState, useEffect } from 'react';
import * as Realm from 'realm-web';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
        if (app.currentUser) {
            setUser(app.currentUser);
            //console.log("user123", app.currentUser);
        }
    }, []);

    const logout = async () => {
        const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
        await app.currentUser?.logOut();
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, setUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
