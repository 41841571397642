import React, { useState, useEffect } from "react";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  useTheme,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { tokens } from "../theme";
import * as Realm from "realm-web";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.title.display = true;
ChartJS.defaults.plugins.title.font = { size: 20 };

//const REALM_APP_ID = "your-realm-app-id"; // Replace with your Realm App ID
const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

const fetchCurrentUserDetails = async () => {
  const userCollection = app.currentUser
    .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
  return await userCollection.findOne({ email: app.currentUser.profile.email });
};

const generateRandomColor = () => {
  let randomColor;
  do {
    randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  } while (randomColor === "#000000"); // Retry if the color is black
  return randomColor;
};

const fetchCompletionData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection(process.env.REACT_APP_MONGO_DB_NATURE_OF_WORK_TABLE);
  const utcStartDate = new Date(startDate).toISOString();

  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        inTime: { $gte: new Date(startDate) },
      },
    },
    {
      $project: {
        normalizedCompletion: {
          $cond: [
            { $eq: ["$isCompleted", true] },
            "Completed",
            "Not Completed",
          ],
        },
      },
    },
    {
      $group: { _id: "$normalizedCompletion", count: { $sum: 1 } },
    },
  ]);
  //console.log("12312start", startDate.toISOString());
  //console.log("12312q", result);

  return result;
};

const fetchAverageTimeData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection(process.env.REACT_APP_MONGO_DB_NATURE_OF_WORK_TABLE);
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        inTime: { $gte: new Date(startDate) },
        outTime: { $ne: null },
        natureOfWork: { $nin: [null, ""] },
      },
    },
    {
      $addFields: {
        duration: { $subtract: ["$outTime", "$inTime"] },
      },
    },
    {
      $group: {
        _id: "$natureOfWork",
        averageTime: { $avg: "$duration" },
      },
    },
  ]);
  return result.map((item) => ({
    _id: item._id,
    averageTime: item.averageTime / 3600000, // Convert duration from milliseconds to hours
  }));
};

const fetchNatureOfWorkCounts = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection(process.env.REACT_APP_MONGO_DB_NATURE_OF_WORK_TABLE);
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        natureOfWork: { $nin: [null, ""] },
        inTime: { $gte: new Date(startDate) },
      },
    },
    { $group: { _id: "$natureOfWork", count: { $sum: 1 } } },
  ]);
  return result;
};

const fetchActionsForIncompleteTasks = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection(process.env.REACT_APP_MONGO_DB_NATURE_OF_WORK_TABLE);
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        isCompleted: false,
        actionTaken: { $nin: [null, ""] },
        inTime: { $gte: new Date(startDate) },
      },
    },
    { $group: { _id: "$actionTaken", count: { $sum: 1 } } },
  ]);
  return result;
};

const fetchComplaintData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection("Breakdown");
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        timeIn: { $gte: new Date(startDate) },
        complaint: { $nin: [null, ""] },
      },
    },
    { $group: { _id: "$complaint", count: { $sum: 1 } } },
  ]);
  return result;
};

const fetchRouteIdData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection("Breakdown");
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        timeIn: { $gte: new Date(startDate) },
        routeId: { $nin: [null, ""] },
      },
    },
    { $group: { _id: "$routeId", count: { $sum: 1 } } },
  ]);
  return result;
};

const fetchBusBreakdownCountData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection("Breakdown");
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        timeIn: { $gte: new Date(startDate) },
      },
    },
    { $group: { _id: "$busPlateNumber", count: { $sum: 1 } } },
  ]);
  return result;
};

const fetchAvgAttendedTimeData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection("Breakdown");
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        timeIn: { $gte: new Date(startDate) },
        timeOut: { $ne: null },
      },
    },
    {
      $addFields: {
        attendedDuration: { $subtract: ["$timeOut", "$timeIn"] },
      },
    },
    {
      $group: {
        _id: null,
        averageAttendedTime: { $avg: "$attendedDuration" },
      },
    },
  ]);
  return result.map((item) => ({
    ...item,
    averageAttendedTime: item.averageAttendedTime / 3600000, // Convert milliseconds to hours
  }));
};

const fetchAvgTimeToCloseBreakdownData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection("Breakdown");

  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        timeIn: { $gte: new Date(startDate) },
        isCompleted: true,
        timeOut: { $ne: null },
      },
    },
    {
      $addFields: {
        durationInHours: {
          $divide: [
            { $subtract: ["$timeOut", "$timeIn"] },
            3600000, // milliseconds to hours conversion
          ],
        },
      },
    },
    {
      $project: {
        busPlateNumber: 1,
        durationInHours: 1,
        timeIn: {
          $dateToString: { format: "%Y-%m-%d %H:%M:%S", date: "$timeIn" },
        }, // Format timeIn to a string
      },
    },
  ]);

  const graphData = result.map((item, index) => ({
    x: index + 1, // Keeping x as a sequential number for simplicity
    y: item.durationInHours,
    busPlateNumber: item.busPlateNumber,
    timeIn: item.timeIn, // Formatted timeIn
  }));
  return graphData;
};

const fetchDriverIdCountData = async (depotId, startDate) => {
  const mongodb = app.currentUser.mongoClient(
    process.env.REACT_APP_MONGO_DB_CLUSTER
  );
  const collection = mongodb
    .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
    .collection("Breakdown");
  const result = await collection.aggregate([
    {
      $match: {
        $or: [
          { depotId: depotId.toString() },
          { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
        ],
        timeIn: { $gte: new Date(startDate) },
        driverId: { $nin: [null, ""] }, // Ensure driverId is not null or empty
      },
    },
    { $group: { _id: "$driverId", count: { $sum: 1 } } }, // Group by driverId and count occurrences
  ]);
  return result;
};

const getStartDateForRange = (range) => {
  const now = new Date();
  switch (range) {
    case "Weekly":
      return new Date(now.setDate(now.getDate() - 7));
    case "Monthly":
      return new Date(now.setMonth(now.getMonth() - 1));
    case "Yearly":
      return new Date(now.setFullYear(now.getFullYear() - 1));
    default:
      return new Date();
  }
};

const BarChart = ({ selectedDaterangeOption }) => {
  const [completionData, setCompletionData] = useState({
    labels: [],
    datasets: [],
  });
  const [averageTimeData, setAverageTimeData] = useState({
    labels: [],
    datasets: [],
  });
  const [natureOfWorkCounts, setNatureOfWorkCounts] = useState({
    labels: [],
    datasets: [],
  });
  const [actionsForIncompleteTasks, setActionsForIncompleteTasks] = useState({
    labels: [],
    datasets: [],
  });

  const [complaintData, setComplaintData] = useState({
    labels: [],
    datasets: [],
  });

  const [routeIdData, setRouteIdData] = useState({
    labels: [],
    datasets: [],
  });

  const [busBreakdownCountData, setBusBreakdownCountData] = useState({
    labels: [],
    datasets: [],
  });

  const [avgAttendedTimeData, setAvgAttendedTimeData] = useState({
    labels: [],
    datasets: [],
  });

  const [driverIdCountData, setDriverIdCountData] = useState({
    labels: [],
    datasets: [],
  });

  const [avgTimeToCloseBreakdownData, setAvgTimeToCloseBreakdownData] =
    useState({
      datasets: [],
    });

  const [selectedSection, setSelectedSection] = useState(() => {
    const savedSection = localStorage.getItem("selectedSection");
    return savedSection ? savedSection : "BreakdownSection";
  });

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    localStorage.setItem("selectedSection", selectedSection);
  }, [selectedSection]);

  useEffect(() => {
    fetchCurrentUserDetails().then((currentUserDetails) => {
      const depotId = currentUserDetails.depotId;
      const startDate = getStartDateForRange(selectedDaterangeOption);
      //console.log("12312", depotId, startDate);

      if (selectedSection === "DenterSection") {
        fetchCompletionData(depotId, startDate).then((data) => {
          setCompletionData({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Completion Status",
                data: data.map((item) => item.count),
                backgroundColor: data.map((item) =>
                  item._id === "Completed" ? "#4CAF50" : "#FF5733"
                ),
              },
            ],
          });
        });

        fetchAverageTimeData(depotId, startDate).then((data) => {
          setAverageTimeData({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Tasks",
                data: data.map((item) => item.averageTime),
                borderColor: "rgb(75, 192, 192)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
              },
            ],
          });
        });

        fetchNatureOfWorkCounts(depotId, startDate).then((data) => {
          setNatureOfWorkCounts({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Complaints",
                data: data.map((item) => item.count),
                backgroundColor: "pink",
                borderColor: "pink",
              },
            ],
          });
        });

        fetchActionsForIncompleteTasks(depotId, startDate).then((data) => {
          setActionsForIncompleteTasks({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Tasks",
                data: data.map((item) => item.count),
                borderColor: "yellow", // Golden Rod
                backgroundColor: "yellow", // Golden Rod with opacity
              },
            ],
          });
        });
      }

      if (selectedSection === "BreakdownSection") {
        fetchComplaintData(depotId, startDate).then((data) => {
          setComplaintData({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Complaints",
                data: data.map((item) => item.count),
                borderColor: "#00BFFF", // Deep Sky Blue
                backgroundColor: "#00BFFF80", // Deep Sky Blue with opacity
              },
            ],
          });
        });

        fetchRouteIdData(depotId, startDate).then((data) => {
          setRouteIdData({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Breakdown on different Routes (route Id)",
                data: data.map((item) => item.count),
                backgroundColor: data.map(() => generateRandomColor()), // Assign a random color to each route
                borderColor: "#FFFFFF", // White border for each item
                borderWidth: 2,
              },
            ],
          });
        });

        fetchAvgTimeToCloseBreakdownData(depotId, startDate).then((data) => {
          setAvgTimeToCloseBreakdownData({
            labels: data.map((_, index) => index + 1),
            datasets: [
              {
                label: "Average Time",
                data: data.map((item) => ({
                  x: item.x,
                  y: item.y,
                  busPlateNumber: item.busPlateNumber,
                  timeIn: item.timeIn,
                })),
                borderColor: "#3CB371", // Medium Sea Green
                backgroundColor: "#3CB37180", // Medium Sea Green with opacity
                fill: false,
              },
            ],
          });
        });

        fetchDriverIdCountData(depotId, startDate).then((data) => {
          setDriverIdCountData({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Breakdowns per driver (driverId)",
                data: data.map((item) => item.count),
                backgroundColor: data.map(() => generateRandomColor()), // Assign a random color to each driver
                borderColor: "#FFFFFF", // White border for each item
                borderWidth: 2,
              },
            ],
          });
        });

        fetchBusBreakdownCountData(depotId, startDate).then((data) => {
          setBusBreakdownCountData({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Bus Number",
                data: data.map((item) => item.count),
                borderColor: "yellow", // Golden Rod
                backgroundColor: "yellow", // Golden Rod with opacity
              },
            ],
          });
        });

        fetchAvgAttendedTimeData(depotId, startDate).then((data) => {
          setAvgAttendedTimeData({
            labels: data.map((item) => item._id),
            datasets: [
              {
                label: "Average Attended Time",
                data: data.map((item) => item.count),
                borderColor: "rgba(255, 206, 86, 1)",
              },
            ],
          });
        });
      }
    });
  }, [selectedSection, selectedDaterangeOption]);

  const handleChangeSection = (event) => {
    setSelectedSection(event.target.value);
  };

  const baseOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "", // Set a default text, which will be overridden for each chart
        padding: {
          top: 10,
          bottom: 10,
        },
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
  };

  const generateOptionsWithTooltips = (title, showCustomTooltip = false) => ({
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      tooltip: showCustomTooltip
        ? {
            callbacks: {
              label: function (context) {
                const dataPoint = context.raw;
                // Convert duration to hours with one decimal place or to minutes if less than 1 hour
                let durationHours = parseFloat(dataPoint.y).toFixed(1);
                let tooltipLabel;
                if (durationHours < 1) {
                  const durationMinutes = Math.round(dataPoint.y * 60);
                  tooltipLabel = `Duration: ${durationMinutes} minutes,`;
                } else {
                  tooltipLabel = `Duration: ${durationHours} hours,`;
                }
                if (dataPoint.busPlateNumber && dataPoint.timeIn) {
                  tooltipLabel += ` Bus: ${dataPoint.busPlateNumber}`;
                }
                return tooltipLabel;
              },
            },
          }
        : undefined,
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
  });

  return (
    <Box sx={{ flexGrow: 1, padding: 2, backgroundColor: "#f5f5f5" }}>
      <Box
        sx={{ backgroundColor: "#141b2d", p: 2, mb: 2, borderRadius: "4px" }}
      >
        {" "}
        {/* Added borderRadius for curve */}
        <FormControl
          fullWidth
          variant="filled"
          sx={{
            backgroundColor: "#f5f5f5",
            color: "black",
            borderRadius: "4px",
          }}
        >
          {" "}
          {/* Matched graph background color and added borderRadius */}
          <InputLabel
            id="section-select-label"
            sx={{ color: "black", ".Mui-focused": { color: "black" } }}
          >
            Select Section
          </InputLabel>
          <Select
            labelId="section-select-label"
            id="section-select"
            value={selectedSection}
            label="Select Section"
            onChange={handleChangeSection}
            sx={{
              color: "black",
              ".MuiSvgIcon-root": { color: "black" },
              "& .MuiSelect-select": {
                backgroundColor: "#f5f5f5", // Ensure the dropdown retains the background color even after selection
              },
              "&:before": {
                // Remove underline
                borderBottomColor: "transparent",
              },
              "&:after": {
                // Remove underline on focus
                borderBottomColor: "transparent",
              },
            }}
          >
            <MenuItem value="BreakdownSection">Breakdown Section</MenuItem>
            <MenuItem value="DenterSection">Denter Section</MenuItem>
            {/* <MenuItem value="BusChargingSection">Bus Charging Section</MenuItem>
            <MenuItem value="GateSection">Gate Section</MenuItem> */}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3} alignItems="stretch">
        {selectedSection === "DenterSection" &&
          [
            {
              label: "Completion Status",
              data: completionData,
              chartType: Doughnut,
              title: "Task Completion %",
            },
            {
              label: "Action Taken on Tasks which were left incomplete",
              data: actionsForIncompleteTasks,
              chartType: Bar,
              title: "Reasons for Incomplete Tasks",
            },
            {
              label: "Average Time taken to complete a Task",
              data: averageTimeData,
              chartType: Line,
              title: "Average Time Taken (in hrs)",
            },
            {
              label: "Distribution of Type of Complaints",
              data: natureOfWorkCounts,
              chartType: Bar,
              title: "Type of Complaints (Count)",
            },
          ].map(({ label, data, chartType: ChartComponent, title }) => (
            <Grid item xs={12} md={6} key={label}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  minHeight: 500,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {label}
                </Typography>
                <Box sx={{ flex: 1, minHeight: 0 }}>
                  <ChartComponent
                    data={data}
                    options={{
                      ...baseOptions,
                      plugins: {
                        ...baseOptions.plugins,
                        title: { ...baseOptions.plugins.title, text: title },
                      },
                    }}
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        {selectedSection === "BreakdownSection" &&
          [
            {
              label: "Bus Breakdown Count Data",
              data: busBreakdownCountData,
              chartType: Bar,
              title: "Breakdowns Per Bus",
            },
            {
              label: "Route ID Distribution",
              data: routeIdData,
              chartType: Doughnut,
              title: "Breakdowns Per Route",
            },
            {
              label: "Complaints received regarding Breakdowns",
              data: complaintData,
              chartType: Bar,
              title: "Complaints (Count)",
            },
            {
              label: "Average Time Taken to Close a Breakdown Case",
              data: avgTimeToCloseBreakdownData,
              chartType: Line,
              title: "Average Time Taken (in hrs)",
              showCustomTooltip: true,
            },
            {
              label: "Driver ID Distribution",
              data: driverIdCountData,
              chartType: Doughnut,
              title: "Breakdowns Per Driver ID",
            },
            {
              label: "Average Attended Time Data",
              data: avgAttendedTimeData,
              chartType: Line,
              title: "Coming Soon",
            },
          ].map(
            ({
              label,
              data,
              chartType: ChartComponent,
              title,
              showCustomTooltip,
            }) => (
              <Grid item xs={12} md={6} key={label}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    minHeight: 500,
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {label}
                  </Typography>
                  <Box sx={{ flex: 1, minHeight: 0 }}>
                    <ChartComponent
                      data={data}
                      options={generateOptionsWithTooltips(
                        title,
                        showCustomTooltip
                      )}
                    />
                  </Box>
                </Paper>
              </Grid>
            )
          )}
        {/* {selectedSection !== "DenterSection" && (
          <Typography variant="h6" sx={{ textAlign: "center", width: "100%" }}>
            {selectedSection === "BusChargingSection"
              ? "Bus Charging Section Content Coming Soon..."
              : "Gate Section Content Coming Soon..."}
          </Typography>
        )} */}
      </Grid>
    </Box>
  );
};

export default BarChart;
