import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Realm from "realm-web";
import { AuthContext } from "../../AuthContext";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../components/navbar";
import "./LoginStyles.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    // Set the background color when the component mounts
    document.body.style.backgroundColor = "#e6f0fa"; // Blueish background

    // Revert the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  if (user) {
    navigate("/dashboard");
    window.location.reload();
    return null;
  }

  const handleLogin = async () => {
    setIsLoading(true);
    if (!email || !password) {
      const errorMessage = "Both email and password are required.";
      toast.error(errorMessage, {
        position: "bottom-center",
        className: "custom-toast-error",
        autoClose: 1000,
      });

      setIsLoading(false);

      return;
    }

    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      await app.logIn(Realm.Credentials.emailPassword(email, password));

      if (!app.currentUser) {
        throw new Error("LoginFailed");
      }

      const mongodb = app.currentUser.mongoClient(
        process.env.REACT_APP_MONGO_DB_CLUSTER
      );
      const usersCollection = mongodb
        .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
        .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
      const userData = await usersCollection.findOne({ email });

      if (!userData || userData.inchargeType !== "Admin") {
        await app.currentUser.logOut();
        throw new Error("NotAdmin");
      }

      navigate("/dashboard");
      window.location.reload();
    } catch (err) {
      console.error("Failed to log in", err);
      let errorMessage = "An unknown error occurred.";

      if (err instanceof Error) {
        if (
          err.message.includes("invalid username/password") ||
          err.message.includes("invalid email/password")
        ) {
          errorMessage =
            "Login failed. Please verify your username and password.";
        } else if (err.message === "NotAdmin") {
          errorMessage =
            "You are not an admin, You do not have access to this portal.";
        }
      }

      setLoginError(errorMessage);
      toast.error(errorMessage, {
        position: "bottom-center",
        className: "custom-toast-error",
        autoClose: 1000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const spinnerStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <>
      <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
        <Navbar />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "100vh",
          overflow: "hidden",
          backgroundColor: "#e6f0fa", // Blueish background for the page wrapper
        }}
      >
        {/* SVG containing the black boxes */}
        <div style={{ position: "relative" }} className="svg-container">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 288 400"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="72"
              y="196"
              width="72"
              height="72"
              transform="rotate(-90 72 196)"
              fill="#28363d"
            ></rect>
            <rect
              x="144"
              y="268"
              width="72"
              height="72"
              transform="rotate(-90 144 268)"
              fill="#28363d"
              style={{ animation: "fadeIn 3s ease-out forwards" }}
            ></rect>
            <rect
              x="216"
              y="196"
              width="72"
              height="72"
              transform="rotate(-90 216 196)"
              fill="#28363d"
              style={{ animation: "fadeIn 3s ease-out forwards" }}
            ></rect>
            <rect
              y="268"
              width="72"
              height="72"
              transform="rotate(-90 0 268)"
              fill="#28363d"
              style={{ animation: "fadeIn 3s ease-out forwards" }}
            ></rect>
          </svg>
        </div>

        <div
          style={{
            height: "auto",
            width: "400px",
            minHeight: "350px",
            padding: "40px 30px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {isLoading ? (
            <>
              <style>{spinnerStyle}</style>
              <div
                style={{
                  border: "4px solid rgba(0, 0, 0, 0.1)",
                  borderTop: "4px solid #4caf50",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  animation: "spin 1s linear infinite",
                  margin: "0 auto",
                }}
              ></div>
            </>
          ) : (
            <>
              <h2
                style={{
                  color: "#2c3e50",
                  fontSize: "26px",
                  fontWeight: "bold",
                  marginBottom: "30px",
                  textAlign: "center",
                  letterSpacing: "0.5px",
                  position: "relative",
                }}
              >
                Welcome to myDepotApp
                <span
                  style={{
                    display: "block",
                    width: "60px",
                    height: "3px",
                    backgroundColor: "#4caf50",
                    position: "absolute",
                    bottom: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                ></span>
              </h2>
              <input
                type="email"
                style={{
                  width: "100%",
                  padding: "14px 16px",
                  margin: "15px 0",
                  border: "1px solid #bdc3c7",
                  borderRadius: "6px",
                  fontSize: "14px",
                  backgroundColor: "#f9f9f9",
                  boxSizing: "border-box",
                  transition: "border-color 0.3s ease",
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <input
                type="password"
                style={{
                  width: "100%",
                  padding: "14px 16px",
                  margin: "15px 0",
                  border: "1px solid #bdc3c7",
                  borderRadius: "6px",
                  fontSize: "14px",
                  backgroundColor: "#f9f9f9",
                  boxSizing: "border-box",
                  transition: "border-color 0.3s ease",
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#4caf50",
                  color: "white",
                  padding: "14px 0",
                  border: "none",
                  borderRadius: "6px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "20px",
                  transition: "background-color 0.3s ease, transform 0.2s ease",
                }}
                onClick={handleLogin}
              >
                Log In
              </button>
              <Link
                to="/forgot-password"
                style={{
                  display: "block",
                  marginTop: "15px",
                  color: "#7f8c8d",
                  textDecoration: "none",
                  fontSize: "15px",
                  textAlign: "center",
                  transition: "text-decoration 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              >
                Forgot Password?
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Responsive CSS for hiding SVG on mobile */}
      <style>
        {`
          @media (max-width: 768px) {
            div[style*="width: 400px"] {
              width: 90% !important; /* Make the container responsive */
              padding: 30px !important; /* Adjust padding for mobile */
            }
            
            h2 {
              font-size: 22px !important; /* Adjust heading size for mobile */
            }

            input {
              font-size: 12px !important; /* Adjust input font size */
              padding: 12px !important; /* Adjust padding for inputs */
            }

            button {
              font-size: 14px !important; /* Adjust button font size */
              padding: 12px 0 !important; /* Adjust button padding */
            }

            a {
              font-size: 15px !important; /* Adjust forgot password link size */
            }

            /* Hide SVG containing the black boxes on mobile */
            .svg-container {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default Login;
