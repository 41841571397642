import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How to create a New User
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To add a user, navigate to the "Add User" option in the sidebar.
            Provide the user's first name, last name, and an email ending with
            @mydepotapp.com. Then, select the user's Incharge Type and set a
            password. Now, the user can log in to myDepotApp with their
            credentials and perform their tasks.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How will a User download the myDepotApp
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Share the below link with users for downloading the app
          </Typography>
          <br />
          <Typography>
            For Android :--
            https://play.google.com/store/apps/details?id=com.myDepotapp2024
          </Typography>
          <Typography>For iOS :-- coming soon.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How do I use the Calendar
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can add your daily tasks to the calendar simply by clicking on
            the day you wish to schedule a task. The calendar also supports
            planning future activities such as inspections, checks, and tests by
            adding events, ensuring that nothing is overlooked. The calendar can
            be viewed in three modes: month, week, and day, allowing you to
            schedule tasks by the hour for precise tracking of work during the
            week or on a specific day.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How do I use the Charts
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can observe and analyze data through charts from various
            sections. These charts allow you to track trends, such as the
            percentage of tasks completed, reasons for task incompletion,
            average time taken to complete tasks, and the distribution of
            drivers experiencing the most breakdowns, including an investigation
            into potential reasons behind these issues. Importantly, data can be
            viewed on a weekly, monthly, and yearly basis.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How does Downloading Sheets work
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can download spreadsheets for all InchargeTypes that have
            assigned users, provided all users have submitted their data by
            creating tasks in the app. The data can be downloaded for various
            durations according to your needs, like 1 day, 1 week or a couple of
            months.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How does Alerts panel work
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Alerts in myDepotApp are displayed according to the initial bus data
            supplied by your depot. Once an alert is marked as completed, its
            date automatically updates to the next scheduled date for that
            event. This process is irreversible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            The Final Question: How do I contact myDepotApp for additional
            inquiries?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For any further questions, queries, feature requests, or if you are
            encountering any issues, please email us at
            "mydepotapphelp@gmail.com" .
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
