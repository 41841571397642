import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar, GridOverlay } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import * as Realm from "realm-web";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
const user = app.currentUser;

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const userCollection = user
          .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
          .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
          .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
        const currentUserDetails = await userCollection.findOne({
          email: user.profile.email,
        });
        if (!currentUserDetails) {
          throw new Error("Current user's details not found.");
        }

        const depotId = currentUserDetails.depotId;
        const queryForDepotIdBothAsStringAndObjectId = {
          $or: [
            { depotId: depotId },
            { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
          ],
        };
        const matchingUsers = await userCollection.find(
          queryForDepotIdBothAsStringAndObjectId
        );
        setUsers(
          matchingUsers.map((user, index) => ({ ...user, id: index + 1 }))
        );
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <CircularProgress style={{ color: colors.greenAccent[700] }} />
      </GridOverlay>
    );
  }

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "inchargeType",
      headerName: "Incharge Type",
      flex: 1,
      renderCell: ({ value }) => {
        let displayText;
        switch (value) {
          case "Gate":
            displayText = "Gate Section";
            break;
          case "DenterSection":
            displayText = "Denter Section";
            break;
          case "BusChargingSection":
            displayText = "Bus Charging Section";
            break;
            case "AccidentalRepairSection":
            displayText = "Accidental Repair Section";
            break;
            case "BatterySection":
            displayText = "Battery Section";
            break;
            case "CngFillingSection":
            displayText = "Cng Filling Section";
            break;
            case "TyreSection":
            displayText = "Tyre Section";
            break;
            case "WashingSection":
            displayText = "Washing Section";
            break;
            case "WorkshopSection":
            displayText = "Workshop Section";
            break;
            case "WashingSection":
            displayText = "Washing Section";
            break;
            case "BreakdownSection":
            displayText = "Breakdown Section";
            break;
            case "Admin":
            displayText = "Admin";
            break;
          default:
            displayText = "No Incharge Type";
        }
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[700]}
            borderRadius="4px"
          >
            {value === "Gate" && <AdminPanelSettingsOutlinedIcon />}
            {value === "DenterSection" && <SecurityOutlinedIcon />}
            {value === "BusChargingSection" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {displayText}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="TEAM" subtitle="Managing the Team Members" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={users}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Team;
