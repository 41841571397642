import React from "react";

function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end", // Align content to the right
        alignItems: "flex-start", // Align content to the top
        height: "100vh",
        padding: "20px", // Add some padding for better visibility
      }}
    >
      <div style={{ fontSize: "7vh" }}>404 - Page Not Found</div>
    </div>
  );
}

function NotFoundButSignedInUser() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh", // This makes sure the content is vertically centered in the viewport
        flexDirection: "column", // Ensures the text is centered and allows for additional items to be easily centered vertically
      }}
    >
      <h1 style={{ margin: 0 }}>
        Oops! The page you are looking for doesn't exist.
      </h1>
      <p>
        However, you are signed in. Click on the sidebar to navigate to your
        desired destination!
      </p>
    </div>
  );
}

export { NotFound, NotFoundButSignedInUser };
