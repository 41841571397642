import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar";

const ForgotPassword = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    // Store the original body background color
    const originalBackgroundColor = document.body.style.backgroundColor;
    // Set the body background color to match the container's background
    document.body.style.backgroundColor = "#d0e6f7"; // Updated background color (light blue)

    return () => {
      // Revert the body background color to its original state on unmount
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  const containerStyle = {
    background: "#d0e6f7", // Updated background color
    color: "#000000", // Updated default text color (black)
    padding: "40px",
    width: "100vw",
    height: "100vh",
    minHeight: "100vh", // Use minHeight to allow content to expand
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const svgBackgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none", // Ensure SVG doesn't interfere with clicking
  };

  const rectangleAnimationStyle = {
    animation: "fadeIn 3s ease-out forwards", // Speed up the animation
  };

  const linkStyle = {
    textDecoration: "underline",
    color: isHovered ? "#4a90e2" : "#000000", // Blue on hover, black by default
    pointerEvents: "auto", // Ensure the link is clickable
    transition: "color 0.3s ease", // Smooth color transition
    fontSize: "20px", // Make the link text larger by default
  };

  const headingStyle = {
    fontSize: "3.5em", // Make heading larger
    marginBottom: "20px",
    color: "#000000", // Change heading to black
  };

  const paragraphStyle = {
    marginTop: "20px",
    textAlign: "center",
    maxWidth: "80%",
    fontSize: "1.8em", // Make paragraph text larger
    color: "#000000", // Change paragraph text to black
  };

  return (
    <>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          .navbar-fixed-top {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000; /* Ensure navbar stays on top */
          }

          /* Reduce font size for the navigation bar on mobile */
          .navbar-fixed-top nav, 
          .navbar-fixed-top a {
            font-size: 18px !important; /* Adjust this value to suit your needs */
          }

          @media (max-width: 768px) {
            /* Adjust font size for mobile */
            h1 {
              font-size: 3em !important;
            }
            /* Make the paragraph smaller for mobile */
            p {
              font-size: 1.5em !important;
            }

            /* Hide the SVG on mobile */
            .svgBackground {
              display: none !important;
            }

            /* Make the link smaller on mobile */
            a {
              font-size: 18px !important;
            }

            /* Reduce font size of the navigation bar text on mobile */
            .navbar-fixed-top nav,
            .navbar-fixed-top a {
              font-size: 16px !important; /* Adjust the font size for mobile navigation */
            }
          }

          @media (max-width: 480px) {
            /* Further reduce font size for very small devices */
            h1 {
              font-size: 2.5em !important;
            }

            p {
              font-size: 1.3em !important;
            }

            a {
              font-size: 16px !important;
            }

            /* Further reduce font size for the navigation bar on smaller devices */
            .navbar-fixed-top nav,
            .navbar-fixed-top a {
              font-size: 15px !important;
            }
          }
        `}
      </style>
      <div className="navbar-fixed-top">
        <Navbar />
      </div>
      <div style={containerStyle}>
        <div style={svgBackgroundStyle} className="svgBackground">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Apply the animation style to each rectangle */}
            <rect
              x="900"
              y="190"
              width="80"
              height="80"
              fill="#28363d"
              style={rectangleAnimationStyle}
            ></rect>
            <rect
              x="1060"
              y="190"
              width="80"
              height="80"
              fill="#28363d"
              style={rectangleAnimationStyle}
            ></rect>
            <rect
              x="980"
              y="270"
              width="80"
              height="80"
              fill="#28363d"
              style={rectangleAnimationStyle}
            ></rect>
            <rect
              x="1200"
              y="350"
              width="80"
              height="80"
              fill="#28363d"
              style={rectangleAnimationStyle}
            ></rect>
            <rect x="1060" y="350" width="80" height="80" fill="#28363d"></rect>
          </svg>
        </div>
        <h1 style={headingStyle}>Forgot Password?</h1>
        <p style={paragraphStyle}>
          In case you've forgotten your password, please contact&nbsp;
          <a
            href="/login"
            style={linkStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <strong>myDepotApp</strong>
          </a>
          &nbsp;immediately to initiate the password reset process.
        </p>
      </div>
    </>
  );
};

export default ForgotPassword;
