import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PersonIcon from "@mui/icons-material/Person";
import Header from "../../components/Header";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import * as Realm from "realm-web";
import { toast } from "react-toastify";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [startDateMMS, setStartDateMMS] = useState(null);
  const [endDateMMS, setEndDateMMS] = useState(null);
  const [startDateDKM, setStartDateDKM] = useState(null);
  const [endDateDKM, setEndDateDKM] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingDepot, setLoadingDepot] = useState(false);
  const [planPurchased, setPlanPurchased] = useState("");
  const [depotName, setDepotName] = useState("");
  const [currentEvents, setCurrentEvents] = useState([]);
  const [depotIds, setDepotIds] = useState("");

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const sendMessage = () => {
    // Here you would have logic to actually send the message
    // For demonstration, we're just showing a toast notification
    toast.info("This feature is not currently available!");
  };

  const handleDownloadAlerts = () => {
    const csvRows = [
      ["Bus Registration Number", "Alert Type", "Date"], // CSV header
      ...currentEvents.map((alert) => [
        `"${alert.registrationNumber}"`, // Ensure data containing commas are enclosed in quotes
        `"${alert.alertType}"`,
        `"${alert.alertDate}"`,
      ]),
    ];

    const csvString = csvRows.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const today = new Date().toISOString().split("T")[0]; // 'yyyy-mm-dd' format for filename
    link.download = `alerts-${today}.csv`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const fetchUserCount = async () => {
      const user = app.currentUser;
      setLoadingUsers(true);
      try {
        const userCollection = user
          .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
          .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
          .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
        const currentUserDetails = await userCollection.findOne({
          email: user.profile.email,
        });
        if (!currentUserDetails) {
          throw new Error("Current user's details not found.");
        }

        const depotId = currentUserDetails.depotId;
        setDepotIds(depotId);
        const queryForDepotIdBothAsStringAndObjectId = {
          $or: [
            { depotId: depotId },
            { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
          ],
        };
        const matchingUsers = await userCollection.find(
          queryForDepotIdBothAsStringAndObjectId
        );

        // Here, instead of setting users, you set the count
        setUserCount(matchingUsers.length); // Set the count of matching users
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoadingUsers(false); // End loading state
      }
    };
    fetchUserCount();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const loadDepotData = async () => {
      setLoadingDepot(true);
      // Attempt to retrieve both values from localStorage
      const storedPlanPurchased = localStorage.getItem("planPurchased");
      const storedDepotName = localStorage.getItem("depotName");

      // Check if both values are already stored
      if (storedPlanPurchased && storedDepotName) {
        setPlanPurchased(storedPlanPurchased);
        setDepotName(storedDepotName);
        setLoadingDepot(false);
      } else {
        const user = app.currentUser;
        try {
          const depotCollection = user
            .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
            .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
            .collection("Depot");
          const currentUserDetails = await user
            .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
            .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
            .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE)
            .findOne({ email: user.profile.email });

          if (!currentUserDetails) {
            throw new Error("Current user's details not found.");
          }
          const depotData = await depotCollection.findOne({
            _id : new Realm.BSON.ObjectId(
              currentUserDetails.depotId.toString())
          });
          // //console.log("userDetails",currentUserDetails);
          // //console.log("depotData",depotData);

          if (depotData) {
            // Update state with fetched data
            const fetchedPlanPurchased = depotData.planPurchased || "-";
            const fetchedDepotName = depotData.name || "-";
            setPlanPurchased(fetchedPlanPurchased);
            setDepotName(fetchedDepotName);

            // Store fetched data in localStorage
            localStorage.setItem("planPurchased", fetchedPlanPurchased);
            localStorage.setItem("depotName", fetchedDepotName);
          } else {
            throw new Error("Depot data not found.");
          }
        } catch (error) {
          console.error("Error fetching depot data:", error);
          // Set default values and clear any potentially stale stored values
          setPlanPurchased("-");
          setDepotName("-");
          localStorage.removeItem("planPurchased");
          localStorage.removeItem("depotName");
        } finally {
          setLoadingDepot(false);
        }
      }
    };

    loadDepotData();
  }, []); // Dependencies array is empty to run only once on component mount

  const fetchBusAlerts = async () => {
    const user = app.currentUser;
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    try {
      const userCollection = user
        .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
        .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
        .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
      const currentUserDetails = await userCollection.findOne({
        email: user.profile.email,
      });

      if (!currentUserDetails || !currentUserDetails.depotId) {
        throw new Error("Current user's details or depot ID not found.");
      }

      const busesCollection = user
        .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
        .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
        .collection("Bus");
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const formattedToday = today.toISOString().split("T")[0];

      const buses = await busesCollection.find({
        $and: [
          {
            $or: [
              { depotId: currentUserDetails.depotId.toString() },
              {
                depotId: new Realm.BSON.ObjectId(
                  currentUserDetails.depotId.toString()
                ),
              },
            ],
          },
          {
            $or: [
              { nextMVI: { $lte: formattedToday } },
              { tenDays: { $lte: formattedToday } },
              { nextPUC: { $lte: formattedToday } },
              { cngPassing: { $lte: formattedToday } },
            ],
          },
        ],
      });

      const alerts = buses.flatMap((bus) => {
        let alertTypesAndDates = [];
        const fieldsToCheck = ["nextMVI", "tenDays", "nextPUC", "cngPassing"];

        fieldsToCheck.forEach((field) => {
          if (bus[field] && new Date(bus[field]) <= today) {
            alertTypesAndDates.push({
              id: bus._id.toString(),
              registrationNumber: bus.registrationNumber,
              alertType:
                field.charAt(0).toUpperCase() +
                field.slice(1).replace(/[A-Z]/g, " $&"), // Format field name
              alertDate: bus[field],
            });
          }
        });

        // Check for kmTillNow exceeding dockingKm
        // if (parseInt(bus.kmTillNow, 10) >= parseInt(bus.dockingKm, 10)) {
        //   alertTypesAndDates.push({
        //     id: bus._id.toString(),
        //     registrationNumber: bus.registrationNumber,
        //     alertType: "Docking",
        //     alertDate: formattedToday,
        //   });
        // }

        return alertTypesAndDates;
      });

      setCurrentEvents(alerts);
    } catch (error) {
      console.error("Error fetching bus alerts:", error);
    }
  };

  useEffect(() => {
    fetchBusAlerts();
  }, []);

  const updateDueDatesForPastBuses = async () => {
    try {
      const user = app.currentUser;
      const userCollection = user
        .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
        .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
        .collection(process.env.REACT_APP_MONGO_DB_USER_TABLE);
      const currentUserDetails = await userCollection.findOne({
        email: user.profile.email,
      });

      if (!currentUserDetails || !currentUserDetails.depotId) {
        throw new Error("Current user's details or depot ID not found.");
      }
      //console.log("depid", currentUserDetails.depotId);
      const busesCollection = user
        .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
        .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
        .collection("Bus");
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const formattedYesterday = yesterday.toISOString().split("T")[0];
      const busesToUpdate = await busesCollection.find({
        $and: [
          {
            $or: [
              { depotId: currentUserDetails.depotId.toString() },
              {
                depotId: new Realm.BSON.ObjectId(
                  currentUserDetails.depotId.toString()
                ),
              },
            ],
          },
          {
            $or: [
              { nextMVI: formattedYesterday },
              { tenDays: formattedYesterday },
              { nextPUC: formattedYesterday },
              { cngPassing: formattedYesterday },
            ],
          },
        ],
      });
      //console.log("today12ewe", busesToUpdate);

      for (const bus of busesToUpdate) {
        const updates = {};

        const dateIncrements = {
          nextMVI: 10,
          tenDays: 20,
          nextPUC: 100,
          cngPassing: 30,
        };

        Object.keys(dateIncrements).forEach((field) => {
          if (bus[field] === formattedYesterday) {
            const newDate = new Date(formattedYesterday);
            newDate.setDate(newDate.getDate() + dateIncrements[field]);
            updates[field] = newDate.toISOString().split("T")[0];
          }
          if (bus[field] === formattedYesterday) {
            const newDate = new Date(formattedYesterday);
            newDate.setDate(newDate.getDate() + dateIncrements[field]);
            updates[field] = newDate; // Directly pass the Date object
          }
        });
        //console.log("132312", updates);
        //console.log("Updating bus _id:", bus._id);
        if (Object.keys(updates).length > 0) {
          try {
            const updateResult = await busesCollection.updateOne(
              { _id: new Realm.BSON.ObjectId(bus._id) },
              { $set: updates }
            );
            //console.log("Update result:", updateResult); //only problem here is that from app, schema is not optional.
          } catch (updateError) {
            console.error(
              "Update operation failed for _id:",
              bus._id,
              updateError
            );
          }
        }
      }
      //console.log("5555555");
    } catch (error) {
      console.error("Error updating due dates for past buses:", error);
    }
  };

  const fetchCompletionData = async (depotId, startDate, endDate) => {
    const mongodb = app.currentUser.mongoClient(
      process.env.REACT_APP_MONGO_DB_CLUSTER
    );
    const collection = mongodb
      .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
      .collection(process.env.REACT_APP_MONGO_DB_GATE_SECTION_TABLE);

    const result = await collection.aggregate([
      {
        $match: {
          $and: [
            {
              $or: [
                { depotId: depotId },
                { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
              ],
            },
            {
              inTime: { $gte: new Date(startDate), $lte: new Date(endDate) },
            },
          ],
        },
      },
      {
        // Use $convert to handle conversion errors
        $addFields: {
          convertedDifferenceInKm: {
            $convert: {
              input: "$differenceInKm",
              to: "int",
              onError: 0, // Default to 0 or any appropriate value on error
              onNull: 0, // Default to 0 when the value is null
            },
          },
        },
      },
      {
        $group: {
          _id: "$busPlateNumber",
          totalKm: { $sum: "$convertedDifferenceInKm" },
        },
      },
      {
        $project: {
          _id: 0,
          busPlateNumber: "$_id",
          totalKm: 1,
        },
      },
    ]);

    //console.log("Aggregation Result:", result);

    return result;
  };

  const fetchCompletionDataWithDailyKm = async (
    depotId,
    startDate,
    endDate
  ) => {
    const mongodb = app.currentUser.mongoClient(
      process.env.REACT_APP_MONGO_DB_CLUSTER
    );
    const collection = mongodb
      .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
      .collection(process.env.REACT_APP_MONGO_DB_GATE_SECTION_TABLE);

    const result = await collection.aggregate([
      {
        $match: {
          $and: [
            {
              $or: [
                { depotId: depotId },
                { depotId: new Realm.BSON.ObjectId(depotId.toString()) },
              ],
            },
            {
              inTime: { $gte: new Date(startDate), $lte: new Date(endDate) },
            },
          ],
        },
      },
      {
        $addFields: {
          date: { $dateToString: { format: "%Y-%m-%d", date: "$inTime" } },
          convertedDifferenceInKm: {
            $convert: {
              input: "$differenceInKm",
              to: "int",
              onError: 0,
              onNull: 0,
            },
          },
        },
      },
      {
        $group: {
          _id: { busPlateNumber: "$busPlateNumber", date: "$date" },
          dailyKm: { $sum: "$convertedDifferenceInKm" },
        },
      },
      {
        $group: {
          _id: "$_id.busPlateNumber",
          dailyKms: {
            $push: {
              date: "$_id.date",
              km: "$dailyKm",
            },
          },
          totalKm: { $sum: "$dailyKm" },
        },
      },
      {
        $project: {
          _id: 0,
          busPlateNumber: "$_id",
          dailyKms: 1,
          totalKm: 1,
        },
      },
    ]);

    //console.log("Aggregation Result23:", result);

    return result;
  };

  const handleDownloadMonthlyMeetSheet = async (
    depotId,
    startDate,
    endDate
  ) => {};
  const handleDownloadDailyKMSheet = async (depotId, startDate, endDate) => {
    try {
      if (!startDate || !endDate) {
        toast.error("Start date and end date are required.");
        return;
      }
      if (new Date(startDate) > new Date(endDate)) {
        toast.info("Start date cannot be later than end date.");
        return;
      }
      const result = await fetchCompletionDataWithDailyKm(
        depotId,
        startDate,
        endDate
      );
      if (!result || result.length === 0) {
        toast.info("No data to download for the selected period.");
        return;
      }

      // Extract all unique dates from the results to form the column headers
      const allDates = [];
      result.forEach((bus) => {
        bus.dailyKms.forEach((day) => {
          if (!allDates.includes(day.date)) {
            allDates.push(day.date);
          }
        });
      });
      allDates.sort(); // Ensure dates are in chronological order

      // Prepare CSV headers
      let csvRows = [["Bus Plate Number", ...allDates, "Total KM Per Bus"]];

      // Initialize a totals row for summing KM across all buses for each date
      // Fill with "-" as placeholders, later to be replaced with totals for each date
      let totalsRow = [
        "Total KM Per Day",
        ...new Array(allDates.length + 1).fill("-"),
      ];

      // Fill in each bus's row and update totalsRow
      result.forEach((bus) => {
        const row = new Array(allDates.length + 2).fill(0); // Initialize row with 0s
        row[0] = bus.busPlateNumber; // Set bus plate number

        bus.dailyKms.forEach((day) => {
          const dateIndex = allDates.indexOf(day.date) + 1; // Find the index for the date
          row[dateIndex] = day.km; // Set the KM for the date
          // Initialize with 0 if currently "-"
          if (totalsRow[dateIndex] === "-") totalsRow[dateIndex] = 0;
          totalsRow[dateIndex] += day.km; // Update the totals row
        });

        row[row.length - 1] = bus.totalKm; // Set total KM in the last column
        csvRows.push(row);
      });

      // Append the totalsRow to the csvRows
      csvRows.push(totalsRow);

      // Convert array of rows into a single CSV string
      const csvString = csvRows.map((row) => row.join(",")).join("\n");

      // Create and trigger the CSV download
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `monthly-meet-sheet-${
        startDate.toISOString().split("T")[0]
      }-to-${endDate.toISOString().split("T")[0]}.csv`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Daily KM Sheet Downloaded.");
    } catch (error) {
      console.error("Failed to fetch completion data", error);
      toast.error("Failed to fetch completion data. Please try again.");
    }
  };

  const markEventAsCompleted = async (busId, eventType) => {
    const currentDate = new Date();
    const formattedEventType = formatEventType(eventType);
  
    // Determine the amount of time to add based on the event type
    let nextDate;
    switch (formattedEventType) {
      case "nextMVI":
        nextDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() + 2)
        );
        break;
      case "cngPassing":
        nextDate = new Date(
          currentDate.setMonth(currentDate.getMonth() + 3)
        );
        break;
      case "tenDays":
        nextDate = new Date(
          currentDate.setDate(currentDate.getDate() + 15)
        );
        break;
      case "nextPUC":
        nextDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() + 1)
        );
        break;
      default:
        console.log("Invalid event type");
        return;
    }
  
    const nextYearFormatted = nextDate.toISOString().split("T")[0];
    //const formattedEventType = formatEventType(eventType);
    //console.log("Debug Log", busId, eventType, formattedEventType, nextYearFormatted);
  
    try {
      const busesCollection = app.currentUser
        .mongoClient(process.env.REACT_APP_MONGO_DB_CLUSTER)
        .db(process.env.REACT_APP_MONGO_DB_COLLECTION)
        .collection("Bus");
  
      // Update the specific event date in the database
      await busesCollection.updateOne(
        { _id: new Realm.BSON.ObjectId(busId) },
        { $set: { [formattedEventType]: nextYearFormatted } }
      );
  
      toast.success(`${eventType} updated successfully.`);
  
      // Optionally, refetch the events to reflect the update
      fetchBusAlerts();
    } catch (error) {
      console.error("Failed to mark event as completed:", error);
      toast.error("Failed to update the event.");
    }
  };

  const formatEventType = (eventType) => {
    return (
      eventType.charAt(0).toLowerCase() + eventType.slice(1).replace(/\s+/g, "")
    );
  };

  return (
    <Box sx={{ m: "20px", overflow: "hidden", minHeight: "100vh" }}>
      {" "}
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleDownloadAlerts}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Alerts Report
          </Button>
        </Box>
      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          flexDirection="column"
          alignItems="flex-start" // Changed from center to flex-start
          justifyContent="center"
          padding="25px"
          sx={{ gap: "8px" }}
        >
          <HomeWorkIcon
            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
          />
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{
              color: colors.grey[100],
              marginTop: "16px", // Adjust this value to increase or decrease the space
            }}
          >
            {loadingDepot ? "-" : depotName}
          </Typography>
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={loadingUsers ? "-" : `${userCount}`}
            subtitle="Total Users"
            progress="0.30"
            increase="+7%"
            icon={
              <PersonIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 7"
          backgroundColor={colors.primary[400]}
          paddingBottom="20px" // Adjust based on the height of the last item
          marginBottom="15px"

          //overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography
              variant="h2"
              fontWeight="bold"
              color={colors.greenAccent[500]}
            >
              Alerts
            </Typography>
            <NotificationsActiveIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px", mr: 3 }}
            />
          </Box>
          <Box
            //height= "780px"
            maxHeight="1000px" // Adjust the height to your preference
            overflow="auto" // Enable scrolling if the content exceeds the fixed height
          >
            {currentEvents.map((alert, i) => (
              <Box
                key={`${alert.id}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
                 marginTop="20px"
                 //marginBottom="20px"
              >
                {/* Registration Number */}
                <Box flex={1} display="flex" alignItems="center">
                  <Typography
                    component="div" // Use 'div' to wrap other elements or for block-level styling
                    variant="h4"
                    color={colors.greenAccent[500]}
                    fontWeight="600"
                  >
                    {alert.registrationNumber}
                  </Typography>
                </Box>

                {/* Alert Date */}
                <Typography
                  component="div" // Correcting element to 'div' to avoid HTML nesting warnings
                  color={colors.grey[100]}
                  variant="body1"
                  sx={{
                    flexGrow: 0,
                    paddingLeft: "-16px",
                    paddingRight: "10px",
                  }} // Adjusted paddingRight to control spacing
                >
                  {new Date(alert.alertDate).toLocaleDateString()}
                </Typography>

                {/* Alert Type */}
                <Box flex={1} display="flex" justifyContent="flex-end">
                  <Typography
                    component="div" // Use 'div' for block-level elements
                    variant="body1"
                    fontWeight="600"
                    p="5px 10px"
                    borderRadius="4px"
                    color="textSecondary" // Assuming you have a color scheme or use MUI's color system
                  >
                    {alert.alertType}
                  </Typography>

                  {/* Example Button for marking an event as completed, assuming functionality to be implemented */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      markEventAsCompleted(alert.id, alert.alertType)
                    } // Assuming you have this function defined
                    sx={{ ml: 2 }}
                  >
                    Mark Done
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Daily Km
              </Typography>
              <Typography
                variant="h2"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Download Daily KM Sheet
              </Typography>
              <Typography variant="h5" color={colors.grey[100]}>
                Select the start and end dates to download the sheet
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" ml={4} mt={10}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* Wrap the DatePicker in a Box and add marginRight for the startDate picker */}
              <Box mr={2} sx={{ display: "inline-flex" }}>
                {" "}
                {/* Adjust mr value as needed */}
                <DatePicker
                  label="Start Date"
                  value={startDateDKM}
                  onChange={setStartDateDKM}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ backgroundColor: "white", borderRadius: 1 }}
                    />
                  )}
                />
              </Box>
              {/* No marginRight needed for the endDate picker since the gap is already created by the startDate picker */}
              <DatePicker
                label="End Date"
                value={endDateDKM}
                onChange={setEndDateDKM}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: "white", borderRadius: 1 }}
                  />
                )}
              />
            </LocalizationProvider>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 2,
              }} /* Adjusted to paddingLeft for consistency */
            >
              <IconButton
                onClick={() =>
                  handleDownloadDailyKMSheet(depotIds, startDateDKM, endDateDKM)
                }
              >
                <DownloadOutlinedIcon
                  sx={{
                    ml: 3, // Adjust if necessary
                    mr: 3,
                    fontSize: "26px",
                    color: colors.greenAccent[500],
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="30px"
          // marginBottom="10px"
        >
          <Typography variant="h5" fontWeight="600">
            Send Special Activity Message
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <TextField
              label="Message"
              variant="outlined"
              multiline
              rows={4} // Specifies the number of lines the text field should allow
              sx={{ mb: 2, width: "100%" }} // Adjust the width as needed
              placeholder="Type your message here"
            />
            <Box sx={{ mb: 2, width: "100%", textAlign: "left" }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Incharge Type
              </Typography>
              <Select
                fullWidth
                displayEmpty
                variant="outlined"
                defaultValue=""
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  Select Incharge Type
                </MenuItem>
                <MenuItem value="BusChargingSection">
                  Bus Charging Section
                </MenuItem>
                <MenuItem value="Gate">Gate Section</MenuItem>
                <MenuItem value="DenterSection">Denter Section</MenuItem>
                <MenuItem value="BreakdownSection">Breakdown Section</MenuItem>
                <MenuItem value="AccidentalRepairSection">
                  Accidental Repair Section
                </MenuItem>
                <MenuItem value="CngFillingSection">
                  Cng Filling Section
                </MenuItem>
                <MenuItem value="WorkshopSection">Workshop Section</MenuItem>
                <MenuItem value="WashingSection">Washing Section</MenuItem>
                <MenuItem value="BatterySection">Battery Section</MenuItem>
                <MenuItem value="TyreSection">Tyre Section</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: "25px" }}
              onClick={sendMessage}
            >
              Send
            </Button>
            <Typography sx={{ mt: "15px" }}>
              This Message will be sent to all the users with the selected
            </Typography>
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{
                fontWeight: "bold",
              }}
            >
              Incharge Types
            </Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom="15px"
        >
          <StatBox
            title={loadingDepot ? "-" : planPurchased}
            subtitle="Plan purchased on myDepotApp"
            progress="0.55"
            increase="+50%"
            icon={
              <ShoppingCartOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
